<template>
  <v-container class="mt-12">
    <v-row class="ma-0 mr-12 d-flex justify-center">
      <template v-for="(column, index) in columns">
        <v-col :key="index" sm="12" xs="12" lg="6">
          <v-row v-if="index === 1">
            <template v-for="(card, index) in column.cards">
              <v-col lg="5" cols="12" :key="index">
                <v-card
                  width="245"
                  height="109.31"
                  class="pa-0 textLight--text headline"
                  flat
                  color="primaryLight"
                >
                  <v-row no-gutters>
                    <v-col cols="2" class="primaryDark d-flex flex-grow-0 column-1"></v-col>
                    <v-col>
                      <p class="mt-5 ml-3">{{ card.title }}</p>
                      <p class="textDark--text ml-3 mt-n4 display-1">{{ card.value }}</p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-responsive v-if="index === 1" :key="`width-${card}`" width="100%"></v-responsive>
            </template>
          </v-row>

          <v-card
            v-else
            flat
            class="mt-3 textLight--text headline"
            color="primaryLight"
            width="505"
            height="243"
          >
            <v-row no-gutters>
              <v-col cols="1" class="primaryDark d-flex flex-grow-0 sub-column-1"></v-col>
              <v-col>
                <p class="ml-3 mt-10 title-tag">{{ column.card.title }}</p>
                <p class="mt-11 pt-12 ml-3 textDark--text display-1">{{ column.card.value }}</p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import AdminHeader from "../components/AdminHeader";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
export default {
  name: "AccountDashboard",
  components: {
    AdminHeader
  },
  created() {},
  data() {
    return {
      columns: [
        {
          card: { title: "Total Account Members", value: "" }
        },
        {
          cards: [
            { title: "Active", value: "" },
            { title: "Accepted", value: "" },
            { title: "Owners", value: "" },
            { title: "Pending", value: "" }
          ]
        }
      ]
    };
  },
  methods: {
    ...mapActions("admin", {
      getAllAccounts: "getAllAccounts"
    })
  },
  async created() {
    let [account] = await this.getAllAccounts();
    let {
      numberOfUsers,
      accountOwnersIds,
      pendingAccountOwners
    } = (this.accountData = account);

    this.columns[0].card.value = this.accountData.numberOfUsers;
    this.columns[1].cards[0].value = this.accountData.numberOfUsers;
    this.columns[1].cards[1].value = this.accountData.numberOfUsers;

    this.columns[1].cards[2].value = this.accountData.accountOwnersIds.length;
    this.columns[1].cards[3].value = this.accountData.pendingAccountOwners.length;
  }
};
</script>

<style scoped>
.column-1 {
  height: 109.31px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 2px solid red;
}

.sub-column-1 {
  height: 243px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.title-tag {
  height: auto;
  width: 150px;
}
</style>
